import React from 'react';

import Layout from '../components/Layout';
import logoMarkBlue from '../img/logo_mark_blue.svg';
import * as styles from './contact-us.module.scss';

const ContactPage = class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formContentClass: styles.contactFormWrapper,
      formSubmittedClass: styles.contactSubmittedWrapper,
      errorSubmitting: false,
      form: {
        office: 'nz',
        email: '',
        name: '',
        message: '',
      },
    };

    this.markFormSubmitted = this.markFormSubmitted.bind(this);
    this.markErrorSubmitting = this.markErrorSubmitting.bind(this);

    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
    this.messageInput = React.createRef();

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillReceiveProps() {
    this.setState({
      formContentClass: styles.contactFormWrapper,
      formSubmittedClass: styles.contactSubmittedWrapper,
      form: {
        office: 'nz',
        email: '',
        name: '',
        message: '',
      },
      errorSubmitting: false,
    });

    this.nameInput.current.value = '';
    this.emailInput.current.value = '';
    this.messageInput.current.value = '';
  }

  markFormSubmitted() {
    this.setState({
      formContentClass: `${styles.contactFormWrapper} ${styles.hidden}`,
      formSubmittedClass: `${styles.contactSubmittedWrapper} ${styles.shown}`,
    });
  }

  markErrorSubmitting() {
    this.setState({
      errorSubmitting: true,
    });
  }

  handleFormChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleFormSubmit(event) {
    event.preventDefault();
    if (process.env.NODE_ENV === 'production') {
      setTimeout(() => {
        window.dataLayer.push({ event: 'contact-submit' });
      }, 50);
    }

    fetch(
      'https://firestore.googleapis.com/v1/projects/lab3-website-1555301298730/databases/(default)/documents/mail',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: {
            to: {
              stringValue:
                this.state.form.office == 'nz'
                  ? `hello.nz@lab3apps.com`
                  : `hello.uk@lab3apps.com`,
            },
            replyTo: {
              stringValue: `${this.state.form.email}`,
            },
            message: {
              mapValue: {
                fields: {
                  subject: {
                    stringValue: 'New message from Lab3 Website',
                  },
                  text: {
                    stringValue: `From: ${this.state.form.name}\nEmail: ${this.state.form.email}\nMessage: ${this.state.form.message}`,
                  },
                },
              },
            },
          },
        }),
      }
    ).then(
      () => {
        this.markFormSubmitted();
      },
      () => {
        this.markErrorSubmitting();
      }
    );
  }

  render() {
    const { formContentClass, formSubmittedClass, errorSubmitting } =
      this.state;
    return (
      <Layout backdropThree>
        <section className={`section after-nav ${styles.section}`}>
          <div className="lab3-container">
            <div className={`${styles.contactForm}`}>
              <div className={styles.logoBackdrop}>
                <img alt="Lab3 Watermark Logo" src={logoMarkBlue} />
              </div>
              <div className={styles.contactContent}>
                <div className={formContentClass}>
                  <h1 className={styles.title}>Contact Us</h1>
                  <div className={styles.contactLine} />
                  <p className={styles.text}>
                    Lab3 Apps is no longer taking on new work, but if you need
                    to get in touch with us we still check our emails.
                  </p>
                  <p className={styles.text}>
                    Fill out the form below and a member of the team will be in
                    contact with you as soon as possible.
                  </p>
                  <form onSubmit={this.handleFormSubmit}>
                    <div className={styles.officeRadios}>
                      <div className={styles.officeRadio}>
                        <input
                          className={styles.radio}
                          type="radio"
                          id="nz"
                          name="office"
                          value="nz"
                          checked
                          onChange={this.handleFormChange}
                        />
                        <label for="nz">
                          <h2 className={styles.officeHeader}>NZ Office</h2>
                          <div className={styles.officeLine} />
                          <p className={styles.officeDetail}>
                            Best for Asia, Australia and US West Coast
                          </p>
                        </label>
                      </div>
                      <div>
                        <input
                          className={styles.radio}
                          type="radio"
                          id="uk"
                          name="office"
                          value="uk"
                          onChange={this.handleFormChange}
                        />
                        <label for="uk">
                          <h2 className={styles.officeHeader}>UK Office</h2>
                          <div className={styles.officeLine} />
                          <p className={styles.officeDetail}>
                            Best for Europe, US East Coast and Africa
                          </p>
                        </label>
                      </div>
                    </div>

                    <label htmlFor="name" className={styles.label}>
                      Name:
                      <input
                        className={styles.field}
                        type="text"
                        name="name"
                        required
                        onChange={this.handleFormChange}
                        ref={this.nameInput}
                      />
                    </label>
                    <label htmlFor="reply" className={styles.label}>
                      Email:
                      <input
                        className={styles.field}
                        type="email"
                        name="email"
                        required
                        onChange={this.handleFormChange}
                        ref={this.emailInput}
                      />
                    </label>
                    <label htmlFor="message" className={styles.label}>
                      Message:
                      <textarea
                        className={styles.field}
                        style={{ height: 140 }}
                        type="text"
                        name="message"
                        required
                        onChange={this.handleFormChange}
                        ref={this.messageInput}
                      />
                    </label>
                    {errorSubmitting ? (
                      <p className={styles.text}>
                        An error occurred, please email us at{' '}
                        <a href="mailto:hello.nz@lab3apps.com">
                          hello.nz@lab3apps.com
                        </a>
                      </p>
                    ) : null}
                    <input
                      className={styles.submit}
                      type="submit"
                      value="SUBMIT"
                    />
                  </form>
                </div>
                <div className={formSubmittedClass}>
                  <h1 className={styles.title}>Thanks</h1>
                  <div className={styles.contactLine} />
                  <p className={styles.text}>We&apos;ll be in touch.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
};

export default ContactPage;
